<template>
  <div>
    <TheNavbar2 />
    <section id="kontak" class="bg-gray-100 py-16">
      <div class="container mx-auto px-4">
        <!-- Call to Action Section -->
        <div class="text-center mb-16">
          <h2 class="text-3xl font-bold mb-4">
            <span class="text-red-600">Daftar Sekarang</span> dan Hubungi Kami!
          </h2>
          <p class="text-lg text-gray-700 mb-8">
            Ingin melakukan pendaftaran? Isi formulir di bawah ini untuk
            mendapatkan informasi lebih lanjut atau langsung hubungi kami!
          </p>
        </div>

        <!-- Contact Form Section -->
        <div class="flex justify-center">
          <div class="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h3 id="judulkontak" class="text-2xl font-bold mb-4 text-center">
              Formulir Kontak
            </h3>
            <form id="kontak-form" class="w-full">
              <div class="mb-6">
                <label
                  for="name"
                  class="block text-gray-700 text-sm font-semibold mb-2"
                  >Nama :</label
                >
                <input type="text" id="name" class="form-input" />
              </div>
              <div class="mb-6">
                <label
                  for="age"
                  class="block text-gray-700 text-sm font-semibold mb-2"
                  >Umur :</label
                >
                <input type="number" id="age" min="0" class="form-input" />
              </div>
              <div class="mb-6">
                <label
                  for="class_or_position"
                  class="block text-gray-700 text-sm font-semibold mb-2"
                  >Kelas/Jabatan :</label
                >
                <input type="text" id="class_or_position" class="form-input" />
              </div>
              <div class="mb-6">
                <label
                  for="psychotest_purpose"
                  class="block text-gray-700 text-sm font-semibold mb-2"
                  >Tujuan Psikotest :</label
                >
                <input type="text" id="psychotest_purpose" class="form-input" />
              </div>
              <div class="mb-6">
                <label
                  for="complaint"
                  class="block text-gray-700 text-sm font-semibold mb-2"
                  >Keluhan :</label
                >
                <textarea id="complaint" rows="5" class="form-input"></textarea>
              </div>
              <div class="mb-6">
                <label
                  for="previous_psychotest"
                  class="block text-gray-700 text-sm font-semibold mb-2"
                  >Sebelumnya sudah pernah psikotest :</label
                >
                <select id="previous_psychotest" class="form-input">
                  <option value="">Pilih...</option>
                  <option value="Sudah">Sudah</option>
                  <option value="Belum">Belum</option>
                </select>
              </div>
              <div class="mb-6">
                <label
                  for="psychotest_date"
                  class="block text-gray-700 text-sm font-semibold mb-2"
                  >Berencana Psikotest di Tanggal :</label
                >
                <input type="date" id="psychotest_date" class="form-input" />
              </div>
              <!-- <button type="submit" class="submit-button">Kirim Pesan</button> -->
            </form>
            <!-- WhatsApp Button -->
            <button @click="sendWhatsAppMessage" class="whatsapp-button mt-6">
              Kirim via WhatsApp
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- Modal for Alerts -->
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <h3 class="modal-title">Peringatan</h3>
        <p>{{ modalMessage }}</p>
        <button @click="closeModal" class="modal-close-button">Tutup</button>
      </div>
    </div>
  </div>
</template>

<script>
import TheNavbar2 from "@/components/TheNavbar2.vue";

export default {
  name: "ContactPage",
  components: {
    TheNavbar2,
  },
  data() {
    return {
      showModal: false,
      modalMessage: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0); // Ensure the page starts at the top
  },
  methods: {
    sendWhatsAppMessage() {
      // Collect form data
      const name = document.getElementById("name").value;
      const age = document.getElementById("age").value;
      const classOrPosition =
        document.getElementById("class_or_position").value;
      const psychotestPurpose =
        document.getElementById("psychotest_purpose").value;
      const complaint = document.getElementById("complaint").value;
      const previousPsychotest = document.getElementById(
        "previous_psychotest"
      ).value;
      const psychotestDate = document.getElementById("psychotest_date").value;

      // Validate form data
      if (
        !name ||
        !age ||
        !classOrPosition ||
        !psychotestPurpose ||
        !complaint ||
        !previousPsychotest ||
        !psychotestDate
      ) {
        this.modalMessage =
          "Semua field harus diisi sebelum mengirimkan pesan.";
        this.showModal = true;
        return;
      }

      // Format the date
      const psychotestDateObject = new Date(psychotestDate);
      const options = {
        weekday: "long",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };
      const formattedDate = psychotestDateObject.toLocaleDateString(
        "id-ID",
        options
      );

      // Construct the message
      const message = `Selamat siang bpk/ibu, Berikut adalah data diri saya\n\nNama : ${name}\nUmur : ${age}\nKelas/Jabatan : ${classOrPosition}\nTujuan psikotest : ${psychotestPurpose}\nKeluhan : ${complaint}\nSebelumnya sudah pernah psikotest : ${previousPsychotest}\nBerencana psikotest di tanggal berapa : ${formattedDate}`;

      // Encode the message for URL
      const encodedMessage = encodeURIComponent(message);

      // WhatsApp API URL
      const whatsappUrl = `https://api.whatsapp.com/send?phone=6281383016462&text=${encodedMessage}`;

      // Open WhatsApp
      window.open(whatsappUrl, "_blank");
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
/* Global Styles */
html,
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden; /* Mencegah scrollbar horizontal */
}

/* Ensure only one scrollbar appears */
body {
  overflow-y: auto; /* Memastikan scrollbar vertikal muncul jika diperlukan */
}

/* Scrollbar Styles for Webkit Browsers */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Scrollbar Styles for Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) #f1f1f1;
}

/* Container Styles */
.scrollable-container {
  overflow-y: auto;
  max-height: 100vh;
}

/* Flexbox Container Styles */
.container {
  display: flex;
  flex-direction: column; /* Memastikan elemen memanjang ke arah yang benar */
  min-height: 100vh; /* Memastikan tinggi minimum sesuai viewport */
}

/* Call to Action Styles */
.text-center {
  text-align: center;
}

.bg-red-600 {
  background-color: #ef4444;
}

.bg-red-700 {
  background-color: #dc2626;
}

.text-red-600 {
  color: #ef4444;
}

.submit-button {
  width: 100%;
  background-color: #ef4444;
  color: white;
  padding: 0.75rem;
  border-radius: 0.375rem;
  font-weight: bold;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #dc2626;
}

/* WhatsApp Button Styles */
.whatsapp-button {
  width: 100%;
  background-color: #25d366; /* WhatsApp Green */
  color: white;
  padding: 0.75rem;
  border-radius: 0.375rem;
  font-weight: bold;
  transition: background-color 0.3s;
}

.whatsapp-button:hover {
  background-color: #128c7e; /* WhatsApp Dark Green */
}

/* Form Input Styles */
.form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.375rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/* Flexbox Utilities */
.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

/* Spacing Utilities */
.mb-16 {
  margin-bottom: 4rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

/* Background Color */
.bg-gray-100 {
  background-color: #f7fafc;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.modal-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.modal-close-button {
  background-color: #ef4444;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1rem;
}

.modal-close-button:hover {
  background-color: #dc2626;
}
</style>
