<template>
  <footer class="bg-red-700 py-6 text-white">
    <div class="container mx-auto px-4 md:px-6">
      <!-- Top Section -->
      <div class="flex flex-col md:flex-row justify-between items-center">
        <!-- Logo (optional) -->
        <!-- <div class="footer-logo mb-4 md:mb-0">
                  <img src="./img/logo.png" alt="triantama-sentosa" class="h-12"/>
                </div> -->

        <!-- Footer Links (Hidden on mobile) -->
        <!-- <div class="footer-links mb-4 md:mb-0 hidden md:flex">
          <ul
            class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6"
          >
            <li>
              <a
                href="#home"
                class="hover:text-gray-300 transition-colors duration-300"
                >Home</a
              >
            </li>
            <li>
              <a
                href="#about"
                class="hover:text-gray-300 transition-colors duration-300"
                >Tentang Kami</a
              >
            </li>
            <li>
              <a
                href="#layanankami"
                class="hover:text-gray-300 transition-colors duration-300"
                >Layanan Kami</a
              >
            </li>
            <li>
              <a
                href="#kontak"
                class="hover:text-gray-300 transition-colors duration-300"
                >Kontak</a
              >
            </li>
          </ul>
        </div> -->

        <!-- Social Media Icons (Hidden on mobile) -->
        <div class="footer-social flex space-x-4 hidden md:flex">
          <a
            href="#"
            class="text-white hover:text-blue-400 transition-colors duration-300"
          >
            <i data-feather="instagram"></i>
            Instagram
          </a>
          <a
            href="#"
            class="text-white hover:text-blue-400 transition-colors duration-300"
          >
            <i data-feather="facebook"></i>
            Facebook
          </a>
          <a
            href="#"
            class="text-white hover:text-red-400 transition-colors duration-300"
          >
            <i data-feather="youtube"></i>
            Youtube
          </a>
        </div>
      </div>

      <!-- Bottom Section -->
      <div class="footer-bottom text-center mt-4 text-sm">
        <p>&copy; 2024 Triantama Sentosa. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  mounted() {
    // Any JS logic if needed
  },
};
</script>

<style scoped>
/* Custom styles for footer if needed */
</style>
