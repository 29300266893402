<template>
  <nav
    :class="[
      'fixed top-0 left-0 w-full z-50 p-4 flex justify-between items-center transition-all',
      { 'bg-white bg-opacity-90': state.isScrolled },
      { 'bg-white': !state.isScrolled },
    ]"
  >
    <!-- Logo Sosmed Kiri -->
    <div class="flex space-x-2 flex-shrink-0">
      <a
        href="https://www.instagram.com/triantamasentosa/"
        class="text-gray-800 hover:text-red-700 transition-colors no-text"
        title="Kunjungi Instagram PT Triantama Sentosa"
      >
        <i data-feather="instagram" class="icon-size" aria-hidden="true"></i>
        <span>Instagram</span>
      </a>
      <a
        href="https://www.facebook.com/triantamasentosa/?locale=id_ID"
        class="text-gray-800 hover:text-red-700 transition-colors no-text"
        title="Kunjungi Facebook PT Triantama Sentosa"
      >
        <i data-feather="facebook" class="icon-size" aria-hidden="true"></i>
        <span>Facebook</span>
      </a>
      <a
        href="https://www.youtube.com/watch?app=desktop&v=fsIzDIHiHdY"
        class="text-gray-800 hover:text-red-700 transition-colors no-text"
        title="Kunjungi Youtube PT Triantama Sentosa"
      >
        <i data-feather="youtube" class="icon-size" aria-hidden="true"></i>
        <span>Youtube</span>
      </a>
    </div>

    <!-- Hamburger Button -->
    <button
      @click="toggleMenu"
      class="lg:hidden text-black hover:text-red-700 transition-colors"
    >
      <i data-feather="menu" class="icon-size"></i>
    </button>

    <!-- Navigation Links for Desktop -->
    <div class="hidden lg:flex items-center space-x-4">
      <a
        :class="{ active: isActive('app') }"
        href="#app"
        class="nav-link"
        @click.prevent="scrollToSection('app')"
      >
        Beranda
      </a>
      <a
        :class="{ active: isActive('about') }"
        href="#about"
        class="nav-link"
        @click.prevent="scrollToSection('about')"
      >
        Tentang Kami
      </a>
      <a
        :class="{ active: isActive('services') }"
        href="#services"
        class="nav-link"
        @click.prevent="scrollToSection('services')"
      >
        Layanan
      </a>
      <a
        :class="{ active: isActive('kontak') }"
        href="#kontak"
        class="nav-link"
        @click.prevent="scrollToSection('kontak')"
      >
        Kontak
      </a>
    </div>

    <!-- Mobile Menu -->
    <div
      :class="[
        'lg:hidden fixed inset-0 bg-white flex flex-col items-center justify-center z-50 transition-transform',
        { 'transform translate-x-0': state.isMenuOpen },
        { 'transform -translate-x-full': !state.isMenuOpen },
      ]"
    >
      <button
        @click="toggleMenu"
        class="absolute top-4 right-4 text-black hover:text-red-700 transition-colors"
      >
        <i data-feather="x" class="icon-size"></i>
      </button>
      <div class="flex flex-col space-y-4 text-white text-lg">
        <a
          :class="{ active: isActive('app') }"
          href="app"
          class="nav-link"
          @click.prevent="scrollToSection('app')"
        >
          Beranda
        </a>
        <a
          :class="{ active: isActive('about') }"
          href="#about"
          class="nav-link"
          @click.prevent="scrollToSection('about')"
        >
          Tentang Kami
        </a>
        <a
          :class="{ active: isActive('services') }"
          href="#services"
          class="nav-link"
          @click.prevent="scrollToSection('services')"
        >
          Layanan
        </a>
        <a
          :class="{ active: isActive('kontak') }"
          href="#kontak"
          class="nav-link"
          @click.prevent="scrollToSection('kontak')"
        >
          Kontak
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { onMounted, onBeforeUnmount, reactive } from "vue";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import feather from "feather-icons";

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: "TheNavbar",
  setup() {
    const state = reactive({
      isScrolled: false,
      isMenuOpen: false,
      activeMenu: "", // Tracks the active menu
    });

    const handleScroll = () => {
      state.isScrolled = window.scrollY > 50;
    };

    const toggleMenu = () => {
      state.isMenuOpen = !state.isMenuOpen;
    };

    const setActive = (menu) => {
      state.activeMenu = menu;
    };

    const isActive = (menu) => {
      return state.activeMenu === menu;
    };

    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        gsap.to(window, {
          scrollTo: section,
          duration: 1,
          ease: "power2.inOut",
        });
      }
      toggleMenu(); // Close the mobile menu after navigation
    };

    onMounted(() => {
      feather.replace();
      handleScroll();
      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      state,
      toggleMenu,
      setActive,
      isActive,
      scrollToSection,
    };
  },
};
</script>

<style scoped>
nav {
  transition: background-color 0.3s, color 0.3s;
}
.no-text {
  display: inline-flex; /* Pastikan tautan dan ikon tidak berubah posisi */
  align-items: center; /* Menjaga agar ikon dan teks sejajar dengan benar */
}

.no-text span {
  display: none; /* Menyembunyikan teks */
}

.icon-size {
  width: 24px;
  height: 24px;
}

/* Custom styles for links */
.nav-link {
  color: black;
  transition: color 0.3s;
}

.nav-link:hover,
.nav-link.active {
  color: #dc2626; /* Change this color to highlight active link */
  /* font-weight: bold; Optional: makes the active link bold */
}

/* Add some styles for the fade transition */
.bg-opacity-90 {
  background-color: rgba(255, 255, 255, 0.9);
}

/* Transition for mobile menu */
.transition-transform {
  transition: transform 0.3s ease-in-out;
}

.mobile-menu {
  transform: translateX(100%);
  width: 100%;
  min-width: 0;
  max-width: 100%;
}

.mobile-menu-open {
  transform: translateX(0);
}

.mobile-menu-bg {
  background-color: black;
}

.mobile-menu-content {
  color: white;
  font-size: 1.125rem;
}

.mobile-menu-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
}
</style>
