<template>
  <div class="container mx-auto p-4">
    <h1 class="text-4xl font-bold">About</h1>
    <p>This is the about page.</p>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style scoped>
/* Optional: Add your styles here */
</style>
