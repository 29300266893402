import { createRouter, createWebHistory } from "vue-router";

// Import views with the correct file names and paths
import HomePage from "../views/HomePage.vue";
import AboutPage from "../views/AboutPage.vue";
import LayananPage from "../views/LayananPage.vue";
import ContactPage from "../views/ContactPage.vue";
import PendaftaranPage from "../views/PendaftaranPage.vue";

const routes = [
  { path: "/", component: HomePage },
  { path: "/about", component: AboutPage },
  { path: "/layanan", component: LayananPage },
  { path: "/contact", component: ContactPage },
  { path: "/pendaftaran", component: PendaftaranPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
