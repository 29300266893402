<template>
  <div class="overflow-x-hidden">
    <TheNavbar />
    <!-- Hero Section -->
    <div
      class="relative flex flex-col items-center justify-center w-full h-screen bg-gradient-to-b from-red-700 to-gray-100 pt-16 px-4"
    >
      <!-- Logo Section -->
      <div class="relative z-10 flex items-center justify-center mb-8">
        <div class="bg-white p-6 rounded-full shadow-lg max-w-full">
          <img
            src="../assets/img/logo.png"
            alt="Logo"
            class="w-32 h-32 max-w-full"
          />
        </div>
      </div>

      <!-- Call to Action and Text -->
      <div class="relative z-10 text-center">
        <h1 class="text-4xl font-bold mb-4 text-gray-800 max-w-full">
          PT Triantama Sentosa
        </h1>
        <p class="text-lg mb-8 text-gray-600 max-w-full">
          Bergabunglah dengan lebih dari 1.000.000 profesional di seluruh
          Indonesia yang telah meningkatkan kemampuan mereka bersama PT
          Triantama Sentosa, penyedia layanan pengembangan SDM dan konsultasi
          terkemuka dengan sertifikasi resmi.
        </p>
        <div class="flex flex-col sm:flex-row gap-4 justify-center">
          <button
            @click="scrollTokontak"
            class="px-6 py-3 bg-red-600 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 transition duration-300"
          >
            Hubungi Kami
          </button>
          <a
            href="/pdf/triantama-sentosa.pdf"
            download="Company Profile PT Triantama Sentosa.pdf"
            class="text-gray-800 font-semibold text-lg hover:text-red-700 transition duration-300 flex items-center"
          >
            Pelajari Lebih Lanjut>
          </a>
        </div>
      </div>
    </div>

    <!-- About Section -->
    <section id="about" class="bg-gray-100 py-16 px-4">
      <div class="container mx-auto px-4 max-w-6xl">
        <div class="flex flex-col md:flex-row items-center">
          <img
            src="../assets/img/tentang.jpg"
            alt="Tentang Kami"
            class="w-full md:w-1/2 h-auto rounded-lg"
            data-aos="fade-right"
          />
          <div class="md:ml-8 mt-8 md:mt-0 max-w-full">
            <h2
              class="text-4xl font-bold mb-4"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <span class="text-red-700">Tentang</span> Kami
            </h2>
            <p
              class="text-lg text-gray-700 mb-4 max-w-full"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              Kami adalah perusahaan yang berdedikasi untuk memberikan solusi
              terbaik dan layanan berkualitas kepada pelanggan kami. Dengan
              pengalaman lebih dari 10 tahun di industri ini, kami berkomitmen
              untuk selalu inovatif dan berfokus pada kepuasan pelanggan.
            </p>
            <p
              class="text-lg text-gray-700 max-w-full"
              data-aos="fade-left"
              data-aos-delay="300"
            >
              Tim kami terdiri dari para ahli di bidangnya masing-masing, yang
              siap membantu Anda mencapai tujuan Anda dengan layanan yang
              profesional dan terpercaya.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- keunggulan kami -->
    <section id="Keunggulan" class="keunggulan-kami bg-gray-100 py-24">
      <h2
        class="text-4xl font-bold text-center mb-12"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <span class="text-red-600">Keunggulan</span> Kami
      </h2>
      <div
        class="keunggulan-kami-container flex flex-wrap justify-center gap-8"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <a
          class="keunggulan-kami-item w-72 p-6 border border-gray-300 rounded-lg bg-white transition-colors duration-300 hover:bg-black hover:text-white text-center flex flex-col items-center group"
          href="/"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="600"
        >
          <img
            src="../assets/img/money.png"
            alt="Harga Kompetitif"
            class="w-20 mx-auto mb-4 transition duration-300 ease-in-out group-hover:invert"
          />
          <h3 class="text-xl mb-2">Harga Kompetitif</h3>
          <p class="text-gray-600">
            Kualitas hasil yang anda dapatkan memiliki nilai yang lebih
            dibandingkan harga yang kami berikan.
          </p>
        </a>
        <a
          class="keunggulan-kami-item w-72 p-6 border border-gray-300 rounded-lg bg-white transition-colors duration-300 hover:bg-black hover:text-white text-center flex flex-col items-center group"
          href="/"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="900"
        >
          <img
            src="../assets/img/handshake.png"
            alt="Anggota HIMPSI"
            class="w-20 mx-auto mb-4 transition duration-300 ease-in-out group-hover:invert"
          />
          <h3 class="text-xl mb-2">Anggota HIMPSI</h3>
          <p class="text-gray-600">
            Tim Psikolog kami aktif dengan izin praktek resmi dari HIMPSI.
          </p>
        </a>
        <a
          class="keunggulan-kami-item w-72 p-6 border border-gray-300 rounded-lg bg-white transition-colors duration-300 hover:bg-black hover:text-white text-center flex flex-col items-center group"
          href="/"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1200"
        >
          <img
            src="../assets/img/report.png"
            alt="Laporan Psikologi Mudah Dipahami"
            class="w-20 mx-auto mb-4 transition duration-300 ease-in-out group-hover:invert"
          />
          <h3 class="text-xl mb-2">Laporan Psikologi Mudah Dipahami</h3>
          <p class="text-gray-600">
            Laporan psikologi yang anda dapatkan dalam bahasa yang sederhana dan
            mudah dimengerti, sehingga dapat memudahkan anda.
          </p>
        </a>
        <a
          class="keunggulan-kami-item w-72 p-6 border border-gray-300 rounded-lg bg-white transition-colors duration-300 hover:bg-black hover:text-white text-center flex flex-col items-center group"
          href="/"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1500"
        >
          <img
            src="../assets/img/coworking.png"
            alt="Efektif Dalam Pengambilan Data"
            class="w-20 mx-auto mb-4 transition duration-300 ease-in-out group-hover:invert"
          />
          <h3 class="text-xl mb-2">Efektif Dalam Pengambilan Data</h3>
          <p class="text-gray-600">
            Pengambilan data dilakukan dengan waktu yang singkat, dengan cara
            sederhana, dan hasil yang maksimal.
          </p>
        </a>
      </div>
    </section>

    <!-- Our Services Section -->
    <section id="services" class="bg-gray-100 py-16">
      <div class="container mx-auto px-4">
        <!-- Title -->
        <h2 class="text-4xl font-bold text-center mb-8">
          <span class="text-red-700">Layanan</span> Kami
        </h2>

        <!-- Promo Banner -->
        <div class="promo-banner text-white p-8 rounded-lg shadow-lg mb-8">
          <div class="text-center">
            <h3 class="text-3xl font-bold mb-4">Promo Psikotest Online!</h3>
            <p class="text-lg mb-4">
              Dapatkan layanan psikotest online dengan harga spesial. Sesuaikan
              kebutuhan Anda dari rumah dan dapatkan hasil yang akurat!
            </p>
            <button
              @click="goToContact"
              class="bg-white text-red-600 py-2 px-6 rounded-lg font-bold hover:bg-gray-200 transition duration-300"
            >
              Hubungi Kami
            </button>
          </div>
        </div>

        <!-- Services Grid -->
        <div
          class="services-grid grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
        >
          <div
            v-for="service in services"
            :key="service.id"
            class="service-item bg-white p-6 rounded-lg border border-gray-200 shadow-md hover:shadow-lg transition-transform duration-300 cursor-pointer"
            @click="openModal(service)"
          >
            <h3 class="text-xl font-bold mb-2">{{ service.name }}</h3>
            <p class="text-gray-700 mb-4">{{ service.description }}</p>
            <div
              v-if="service.features && service.features.length"
              class="mb-4"
            >
              <h4 class="text-lg font-semibold mb-2">Fitur dan Manfaat:</h4>
              <ul class="list-disc pl-5 space-y-2">
                <li v-for="feature in service.features" :key="feature">
                  {{ feature }}
                </li>
              </ul>
            </div>
            <div v-if="service.price" class="mt-4">
              <h4 class="text-lg font-semibold">Harga:</h4>
              <p class="text-lg font-bold whitespace-pre-line">
                {{ service.price }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div
        v-if="isModalOpen"
        class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50"
        @click="closeModalOnBackgroundClick"
      >
        <div
          class="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative"
          @click.stop
        >
          <button
            @click="closeModal"
            class="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h3 class="text-2xl font-bold mb-4">{{ selectedService?.name }}</h3>
          <p class="text-gray-700 mb-4">{{ selectedService?.description }}</p>
          <div
            v-if="selectedService?.features && selectedService?.features.length"
            class="mb-4"
          >
            <h4 class="text-lg font-semibold mb-2">Fitur dan Manfaat:</h4>
            <ul class="list-disc pl-5 space-y-2">
              <li v-for="feature in selectedService.features" :key="feature">
                {{ feature }}
              </li>
            </ul>
          </div>
          <div v-if="selectedService?.price" class="mt-4">
            <h4 class="text-lg font-semibold">Harga:</h4>
            <p class="text-lg font-bold whitespace-pre-line">
              {{ selectedService?.price }}
            </p>
          </div>
          <button
            @click="goToContact"
            class="bg-red-600 text-white py-2 px-4 rounded-lg mt-4 hover:bg-red-700 transition duration-300"
          >
            Hubungi Kami
          </button>
        </div>
      </div>
    </section>
    <!-- Contact Section -->
    <section id="kontak" class="contact-section">
      <h2 class="contact-heading"><span>Kontak</span> Kami</h2>
      <div class="contact-container">
        <div class="bg-white p-8 rounded-lg shadow-lg max-w-md mx-auto">
          <h3 class="text-2xl font-bold mb-4">Informasi Kontak</h3>
          <p class="mb-2">
            <strong>Alamat:</strong> Jl. Dili Raya No.4 Blok A8, RT.003/RW.010,
            Bekasi Jaya, Kec. Bekasi Tim., Kota Bks, Jawa Barat 17112
          </p>
          <p class="mb-2"><strong>Telepon:</strong> 0813-8301-6462</p>
          <!-- <p class="mb-2"><strong>Telepon:</strong> </p> -->
          <p class="mb-2">
            <strong>Email:</strong> triantama.marketing@gmail.com
          </p>
          <!-- <p class="mb-2"><strong>Jam Operasional:</strong></p> -->
          <!-- <ul class="list-disc pl-5">
            <li>Senin - Jumat: 09.00 - 16.00</li>
            <li>Sabtu: 09.00 - 16.00</li>
            <li>Minggu: Tutup</li>
          </ul> -->
          <!-- Google Maps -->
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.2151952877643!2d107.00772787409647!3d-6.2353402610589885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f4f92323e163%3A0x30889828fbf3635c!2sPT%20Triantama%20Sentosa!5e0!3m2!1sen!2sid!4v1722682406541!5m2!1sen!2sid"
            width="100%"
            height="250"
            title="alamat PT Triantama Sentosa"
            style="border: 0; border-radius: 12px"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div class="contact-form">
          <form @submit.prevent="sendWhatsAppMessage">
            <div class="form-group">
              <label for="name">Nama</label>
              <input
                type="text"
                v-model="name"
                id="name"
                name="name"
                required
              />
            </div>
            <div class="form-group">
              <label for="message">Pesan</label>
              <textarea
                v-model="message"
                id="message"
                name="message"
                rows="5"
                required
              ></textarea>
            </div>
            <button type="submit" class="submit-button">Kirim Pesan</button>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import TheNavbar from "@/components/TheNavbar.vue";
import AOS from "aos";
import "aos/dist/aos.css";

gsap.registerPlugin(ScrollToPlugin);
export default {
  name: "HomePage",
  components: {
    TheNavbar,
  },
  data() {
    return {
      name: "",
      message: "",
      services: [
        {
          id: 1,
          name: "Assessment Center",
          description:
            "Penilaian komprehensif untuk menentukan potensi dan kecocokan calon.",
          features: [
            "Penilaian psikologis mendalam",
            "Pengukuran kompetensi dan potensi",
            "Rekomendasi pengembangan",
          ],
          price: "Mulai dari Rp2.000.000",
        },
        {
          id: 2,
          name: "Manajerial Level",
          description:
            "Psikotest untuk level manajer untuk penilaian kemampuan manajerial.",
          features: [
            "Evaluasi kemampuan kepemimpinan",
            "Analisis gaya manajerial",
            "Penilaian kompetensi strategis",
          ],
          price: "Rp900.000",
        },
        {
          id: 3,
          name: "Supervisor",
          description:
            "Psikotest untuk supervisor untuk penilaian keterampilan supervisi.",
          features: [
            "Penilaian kemampuan supervisi",
            "Analisis keterampilan komunikasi",
            "Evaluasi pengambilan keputusan",
          ],
          price: "Rp775.000",
        },
        {
          id: 4,
          name: "Non Staff",
          description: "Psikotest untuk posisi non-staff.",
          features: [
            "Penilaian dasar psikologis",
            "Evaluasi kecocokan pekerjaan",
            "Pemeriksaan kepribadian",
          ],
          price: "Rp575.000",
        },
        {
          id: 5,
          name: "Staff with Interview",
          description: "Psikotest dan wawancara untuk posisi staff.",
          features: [
            "Psikotest dan wawancara terintegrasi",
            "Penilaian kecocokan dengan budaya perusahaan",
            "Evaluasi keterampilan interpersonal",
          ],
          price: "Rp750.000",
        },
        {
          id: 6,
          name: "ABK",
          description: "Psikotest untuk ABK (Anak Berkebutuhan Khusus).",
          features: [
            "Penilaian kebutuhan khusus",
            "Evaluasi potensi dan kekuatan",
            "Pendekatan personal",
          ],
          price: "Rp550.000",
        },
        {
          id: 7,
          name: "Tes Individual",
          description: "Tes individual berdasarkan usia.",
          features: [
            "Penilaian berbasis usia",
            "Tes kemampuan kognitif",
            "Evaluasi psikologis mendalam",
          ],
          price: "Rp550.000",
        },
        {
          id: 8,
          name: "Konseling",
          description: "Layanan konseling untuk berbagai kebutuhan psikologis.",
          features: [
            "Sesi konseling individu",
            "Pendekatan berbasis kebutuhan",
            "Dukungan psikologis profesional",
          ],
          price: "Rp350.000",
        },
        {
          id: 9,
          name: "Laporan Pendek",
          description: "Laporan psikologis singkat sesuai jenjang pendidikan.",
          features: [
            "Laporan untuk TK, SD, SMP, SMA",
            "Analisis ringkas",
            "Rekomendasi tindak lanjut",
          ],
          price: `
            TK: Rp300.000
            SD: Rp350.000
            SMP: Rp405.000
            SMA: Rp450.000
          `,
        },
        {
          id: 10,
          name: "Laporan Panjang",
          description: "Laporan psikologis mendalam sesuai jenjang pendidikan.",
          features: [
            "Laporan mendalam untuk TK, SD, SMP, SMA",
            "Analisis komprehensif",
            "Rekomendasi detail",
          ],
          price: `
            TK: Rp350.000
            SD: Rp400.000
            SMP: Rp450.000
            SMA: Rp475.000
          `,
        },
        // Add more services as needed
      ],
      selectedService: null,
      isModalOpen: false,
    };
  },
  mounted() {
    AOS.init({
      duration: 1000, // Duration of animations
      once: true, // Animations only occur once
    });
  },
  methods: {
    openModal(service) {
      this.selectedService = service;
      this.isModalOpen = true;
      document.body.style.overflow = "hidden"; // Prevent scrolling when modal is open
    },
    closeModal() {
      this.isModalOpen = false;
      document.body.style.overflow = ""; // Restore scrolling
    },
    closeModalOnBackgroundClick(event) {
      if (event.target === event.currentTarget) {
        this.closeModal();
      }
    },
    scrollToContact() {
      document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
    },
    sendWhatsAppMessage() {
      const message = encodeURIComponent(
        `Halo kak, nama saya ${this.name}, ${this.message}`
      );
      const url = `https://api.whatsapp.com/send?phone=6281383016462&text=${message}`;
      window.open(url, "_blank");
    },
    goToContact() {
      this.$router.push("/contact");
    },
    scrollTokontak() {
      // Scroll smoothly to the element with ID 'kontak'
      gsap.to(window, { duration: 1, scrollTo: "#kontak" });
    },
  },
};
</script>

<style scoped>
.promo-banner {
  background-color: #f87171; /* Solid color */
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='84' viewBox='0 0 84 84' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.18'%3E%3Cpath d='M84 23c-4.417 0-8-3.584-8-7.998V8h-7.002C64.58 8 61 4.42 61 0H23c0 4.417-3.584 8-7.998 8H8v7.002C8 19.42 4.42 23 0 23v38c4.417 0 8 3.584 8 7.998V76h7.002C19.42 76 23 79.58 23 84h38c0-4.417 3.584-8 7.998-8H76v-7.002C76 64.58 79.58 61 84 61V23zM59.05 83H43V66.95c5.054-.5 9-4.764 9-9.948V52h5.002c5.18 0 9.446-3.947 9.95-9H83v16.05c-5.054.5-9 4.764-9 9.948V74h-5.002c-5.18 0-9.446 3.947-9.95 9zm-34.1 0H41V66.95c-5.053-.502-9-4.768-9-9.948V52h-5.002c-5.184 0-9.447-3.946-9.95-9H1v16.05c5.053.502 9 4.768 9 9.948V74h5.002c5.184 0 9.447 3.946 9.95 9zm0-82H41v16.05c-5.054.5-9 4.764-9 9.948V32h-5.002c-5.18 0-9.446 3.947-9.95 9H1V24.95c5.054-.5 9-4.764 9-9.948V10h5.002c5.18 0 9.446-3.947 9.95-9zm34.1 0H43v16.05c5.053.502 9 4.768 9 9.948V32h5.002c5.184 0 9.447 3.946 9.95 9H83V24.95c-5.053-.502-9-4.768-9-9.948V10h-5.002c-5.184 0-9.447-3.946-9.95-9zM50 50v7.002C50 61.42 46.42 65 42 65c-4.417 0-8-3.584-8-7.998V50h-7.002C22.58 50 19 46.42 19 42c0-4.417 3.584-8 7.998-8H34v-7.002C34 22.58 37.58 19 42 19c4.417 0 8 3.584 8 7.998V34h7.002C61.42 34 65 37.58 65 42c0 4.417-3.584 8-7.998 8H50z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 84px 84px; /* Size of the SVG pattern */
  background-repeat: repeat; /* Repeat the pattern */
  padding: 1rem; /* Base padding */
}
.services-grid .service-item:hover {
  transform: scale(1.05);
}
.group-hover\:invert:hover {
  filter: invert(1);
}

/* kontak */
.contact-section {
  @apply bg-gray-100 py-16 px-4;
}

.contact-heading {
  @apply text-4xl font-bold text-center mb-12;
}

.contact-heading span {
  @apply text-red-600;
}

.contact-container {
  @apply max-w-4xl mx-auto flex flex-col md:flex-row gap-8;
}

.contact-maps {
  @apply flex-1;
}

.contact-form {
  @apply flex-1;
}

.contact-form form {
  @apply bg-white p-6 rounded-lg shadow-md;
}

.form-group {
  @apply mb-6;
}

.form-group label {
  @apply block text-lg font-semibold mb-2;
}

.form-group input,
.form-group textarea {
  @apply w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-red-500;
}

.submit-button {
  @apply bg-red-600 text-white py-2 px-6 rounded-lg font-bold hover:bg-red-700 transition duration-300;
}

/* .contact-maps iframe {
  @apply w-full h-64 border-0;
} */

/* Tambahkan padding tambahan untuk setiap section */
.section-padding {
  padding-top: 5rem; /* Ubah sesuai kebutuhan */
  position: relative;
  margin-top: -5rem; /* Menghilangkan jarak tambahan di atas */
}

/* Jika menggunakan scroll-margin-top */
section {
  scroll-margin-top: 5rem; /* Ubah sesuai kebutuhan */
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Scrollbar Styles for Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) #f1f1f1;
}

/* Container Styles */
.scrollable-container {
  overflow-y: auto;
  max-height: 100vh;
}
</style>
