<template>
  <div class="container mx-auto p-4">
    <h1 class="text-4xl font-bold">Layanan</h1>
    <p>Here are the services we offer.</p>
  </div>
</template>

<script>
export default {
  name: "LayananPage",
};
</script>

<style scoped>
/* Optional: Add your styles here */
</style>
