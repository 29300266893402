<template>
  <div id="app">
    <!-- <TheNavbar /> -->
    <router-view />
  </div>
  <Footer />
</template>

<script>
// import TheNavbar from "./components/TheNavbar.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    // TheNavbar,
    Footer,
  },
};
</script>

<style>
/* Global styles can go here */
</style>
